import '../style/index.css';
import Map from './map';
import Location from '../images/location.png';
import Phone from '../images/phone.png';
import Mail from '../images/email.png';
import Facebook from '../images/facebook.png';
import Instagram from '../images/instagram.png';

function Contact() {
    return (
        <section id="contact">
                <Map />
            <div className='contact'>
                <div className="contactColonne">
                    <h3>Horaires d'ouvertures:</h3>
                    <p>Lundi, jeudi, vendredi, samedi et dimanche: de 10h à 2h *</p>
                    <p className='small'>* selon la fréquentation</p>
                </div>
                <div className="contactLigne">
                    <img src={Location} alt="Adresse" />
                    <p>Av. Marcel Paul, 64300 Orthez</p>
                </div>
                <div className="contactLigne">
                    <img src={Phone} alt="Téléphone" />
                    <p>05 59 67 34 09</p>
                </div>
                <div className="contactLigne">
                    <img src={Mail} alt="Email" />
                    <p>lelouisabella@gmail.com</p>
                </div>
                <div className="contactLigne">
                    <p>Pour suivre nos actualités, retrouvez-nous sur : </p>
                    <a href="https://www.facebook.com/profile.php/?id=100093590869297&paipv=0&eav=AfY3cePjJoh96DK6IIc2d9cS01y6pQ8r5LRIbbcENUS4JOyEJ8L27PpYrmZ7JioujG0&_rdr"><img src={Facebook} alt="Facebook" className="sociaux" /></a>
                    <a href="https://www.instagram.com/lelouisabella/"><img src={Instagram} alt="Instagram" className="sociaux"/></a>                    
                </div>

            </div>
        </section>
    )
}

export default Contact;